<template>
	<div >
        <div v-html="this.campaignData.sMailBody"></div>
	</div>

</template>
<script>
/* eslint-disable */
export default {
    props: ['idAdvertisingCampaign'],
	data() {
		return {
            campaignData: {
                sMailBody: ''
            }
		}
	},
	created() {
	},
	async mounted() {
        await this.getData()
	},
	methods: {
        async getData(){
            await axios.get(apiURL + 'AdvertisingCampaign/findAdvertisingCampaign?idAdvertisingCamping=' + this.idAdvertisingCampaign, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                this.campaignData = response.data.advertisingCampingData
            } )
            .catch( (error)  => {
                console.log("error")
            } )     
            console.log(this.campaignData)        
        }
	}
}
</script>

<style scoped lang="scss">

</style>
