<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
                <DataTable :value="campaigns" responsiveLayout="scroll" ref="dt"  showGridlines  :paginator="true" :rows="10"  v-model:expandedRows="expandedRows" dataKey="idShopifyOrder" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} to {last} of {totalRecords} productos" >
                    <template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Campañas de Publicidad</h5>
							<div class="my-1">
								<Button label="Agregar Campaña" icon="pi pi-chart-line" class="p-button-success mr-2" @click="AddCampaign" />
							</div>

							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
					</template>   
                    <Column field="sSegmentType" header="Lista Usuarios" ></Column>
                    <Column field="sDescription" header="Descripción" ></Column>
                    <Column field="dRegisterDate" header="Fecha" :sortable="true">
                        <template #body="slotProps">
                            {{ formatDate(slotProps.data.dRegisterDate) }}
                        </template>
                    </Column>
                    <Column field="sAdvertisingCampaignStatus" header="Estado" ></Column>
                    <Column field="dAdvanced" header="Avance" >
                        <template #body="slotProps">
                            {{ slotProps.data.dAdvanced }}%
                        </template>                    
                    </Column>
					<Column >
						<template  #body="slotProps">
							<a v-if="slotProps.data.idAdvertisingCampaignStatus != 3" href="#" @click.prevent="sendMail(slotProps.data.idAdvertisingCampaign)" style="padding-right: 5px;"><i class="pi pi-envelope"></i></a>
							<a href="#" @click.prevent="openPreview(slotProps.data.idAdvertisingCampaign)" style="padding-right: 5px;"><i class="pi pi-eye" ></i></a>
							<a v-if="slotProps.data.idAdvertisingCampaignStatus != 3" href="#" @click.prevent="goEdit(slotProps.data.idAdvertisingCampaign)" style="padding-right: 5px;"><i class="pi pi-pencil"></i></a>
							
						</template>
					</Column>	                    
                </DataTable>
            </div>
        </div>
        <Dialog v-model:visible="previewDialog" :style="{width: '950px'}" header="Vista previa" :modal="true" class="p-fluid">
            <PreviewComponent :idAdvertisingCampaign = this.idSelectedCampaign />
        </Dialog>
    </div>
</template>

<script>
    import {FilterMatchMode} from 'primevue/api';
    import PreviewComponent from "../components/AdvertisingCampaign/PreView.vue"
/* eslint-disable */
export default {
    components: {
        PreviewComponent,
    }, 	
	data() {
		return {
            previewDialog: false,
			campaigns: null,
            expandedRows: null,
            filters: {},
            idSelectedCampaign: null
		}
	},
	created() {
        this.initFilters();
	},
	mounted() {
        this.isTokenExpired()
        this.getCampaigns()
		
	},
	methods: {
        initFilters() {
                this.filters = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                }
            },  
        formatC(value) {
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			},
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        formatDate(value) {
            const d = new Date(value);;
				return d.toLocaleDateString('en-US', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
        async getCampaigns(){
            await axios.get(apiURL + 'AdvertisingCampaign/getAdvertisingCampaignList', { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                this.campaigns = response.data.advertisingCampaignList
            } )
            .catch( (error)  => {
                console.log("error")
            } ) 
        },
        async AddCampaign(){
            router.push('/addCampaign')
        },
        async sendMail(idAdvertisingCamping){
            let bandConfirm = false
            await Swal.fire({
                title: 'Esta acción enviará los correos a los clientes restantes',
                text: "¿Desea enviar los correos?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, enviarlos',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    bandConfirm = true
                }
                else{
                    bandConfirm = false
                }
            })

            if(bandConfirm == false){
                Swal.fire('No se enviaron los correos')
                return
            }

            this.openLoading('Enviando...')
            await axios.post(apiURL + 'AdvertisingCampaign/sendMails?idAdvertisingCamping=' + idAdvertisingCamping, null, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                Swal.close()
                if(response.data.bCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })						
                    return
                }
                else{
                    Swal.fire('Correos enviados correctamente')
                    this.getCampaigns()
                }
            } )
            .catch( (error)  => {
                Swal.close()
                console.log("error")
            } )            
        },

        openPreview(idAdvertisingCamping){
            this.idSelectedCampaign = idAdvertisingCamping
            this.previewDialog = true
        },
        goEdit(idAdvertisingCampaign){
            router.push('/editCampaign/' + idAdvertisingCampaign)
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}
</style>              

